<template>
    <div>
        <el-dialog title="充值" :visible.sync="dialogFormVisible" >
            <el-form :model="form"  :rules="rules">
                <el-form-item label="充值金额" :label-width="formLabelWidth" prop="shifu">
                    <el-input v-model.number="form.shifu" autocomplete="off">
                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>
                </el-form-item>
                <el-form-item label="赠送金额" :label-width="formLabelWidth" prop="zengsong">
                    <el-input v-model.number="form.zengsong" autocomplete="off">
                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>
                </el-form-item>
                <el-form-item label="充值方式" :label-width="formLabelWidth" prop="fangShi">
                    <x-selector-one v-model="form.fangShi" dictType="T_CHONG_ZHI_FS"/>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="beiZhu">
                    <el-input type="textarea" v-model="form.beiZhu" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="chongzhi">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import {DICT_TYPE_BDCJ, DICT_TYPE_CHONG_ZHI_WAY} from "@/util/constant";
    import {ruleBuilder,isDigits} from "@/util/validate";

    export default {
        name: "ToChongZhi",
        mixins: [],
        data() {
            let {required,digits}=ruleBuilder;
            return {
                dialogFormVisible: false,
                form: {
                    shifu: 0,
                    zengsong: 0,
                    fangShi: DICT_TYPE_CHONG_ZHI_WAY.XJZF.key,
                    id:'',
                    beiZhu:"",
                },
                formLabelWidth: '120px',
                rules:{
                    fangShi:[required()],
                    shifu:[{ required: true, message: '充值金额不能为空', trigger:'blur'},digits()],
                    zengsong:[{ required: true, message: '赠送金额不能为空', trigger:'blur'},digits()],
                },
            };
        },
        methods: {
            chongzhishow(id){
                this.dialogFormVisible = true
                this.form.id=id
            },
            chongzhi(){
                if (!isDigits(this.form.shifu) || !isDigits(this.form.zengsong)){
                    this.$message.error("充值金额必须为整额")
                    return false
                }
                // if (this.menDianMC==="" || this.menDianMC===null || this.dianZhangMC==="" || this.dianZhangMC===null){
                //     this.$message.error("请先选择门店")
                //     return false
                // }

                this.dialogFormVisible = false;

                let data={
                    id:this.form.id,
                    shiFuJinE: this.form.shifu*1, // 充值金额
                    zengSongJinE: this.form.zengsong*1, // 赠送金额
                    daoZhangJinE: this.form.shifu*1+this.form.zengsong*1,
                    chongZhiMenDian: this.$store.getters.user.ownerDeptName, // 所属门店
                    chongZhiChangJing:DICT_TYPE_BDCJ.MDHT.key,//充值场景
                    chongZhiFS:this.form.fangShi,//充值方式
                    caoZuoRenYuan: this.$store.getters.user.name, // 操作人
                    beiZhu:this.form.beiZhu,
                }
                // console.log(data)
                hyxxservice.chongzhi(data).then(res=>{
                    // console.log(res)
                    if (res.code===200){
                        this.$emit('success')
                        this.$message.success("充值成功")
                        this.form.shifu=0;
                        this.form.zengsong=0;
                    }else {
                        this.$message.warning("充值失败，请检查用户状态是否正常")
                    }
                })
            },
        }
    };
</script>

<style lang="scss" scoped>

</style>