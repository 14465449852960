<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="卡号" >
                <el-input v-model="search.kaHao" size="small" />
            </x-search-item>
            <x-search-item label="姓名" >
                <el-input v-model="search.xingMing" size="small" />
            </x-search-item>
            <x-search-item label="手机号" >
                <el-input v-model="search.shouJiHao" size="small" />
            </x-search-item>
            <x-search-item label="会员类型" >
<!--                <el-select v-model="search.huiYuanLeiXing" placeholder="请选择" size="small">-->
<!--                    <el-option v-for="item in hylxList" :label="item.name" :value="item.name">-->
<!--                    </el-option>-->
<!--                </el-select>-->
                <x-selector-one  v-model="search.huiYuanLeiXing" dictType="T_HUI_YUAN_LX"/>
            </x-search-item>
            <x-search-item label="卡状态" >
<!--                <el-select v-model="search.kaZhuangTai" placeholder="请选择" size="small">-->
<!--                    <el-option v-for="item in kaZTList" :key="item.value" :label="item.value" :value="item.value">-->
<!--                    </el-option>-->
<!--                </el-select>-->
                <x-selector-one  v-model="search.kaZhuangTai" dictType="T_KA_ZT"/>
            </x-search-item>
            <x-search-item label="开卡时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.kaiKaSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.kaiKaSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="所属门店" >
<!--                <el-input v-model="search.suoShuMenDian" size="small" />-->
                <el-select v-model="search.suoShuMenDian" placeholder="请选择" size="small">
                    <el-option
                            v-for="item in menDianList"
                            :key="item.menDianMC"
                            :label="item.menDianMC"
                            :value="item.menDianMC">
                    </el-option>
                </el-select>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>

<!--                    <el-button type="primary" size="mini" @click="exportExcel" style="background-color: #f0f0f0;color: black;border: #8f8f9d 1px solid;margin-left: 10px;">导出会员信息>></el-button>-->
<!--                    <label style="margin-left: 30px;font-weight: bold">导入会员信息：</label>-->
<!--                    <div id="uploadFile" style="line-height: 35px">-->
<!--                        <input type="file" ref="upload"  accept=".xls,.xlsx" >-->
<!--                    </div>-->
<!--                    <el-button type="primary" size="mini">-->
<!--                        <a  href="./static/hygl/会员信息.xlsx" style="color: #fff" download="会员信息.xlsx">下载导入模板</a>-->
<!--                    </el-button>-->


<!--                <el-button type="primary" size="mini">-->
<!--                    <label style="font-weight: bold" @click="daoRuShow">导入会员信息</label>-->
<!--                </el-button>-->
            </div>
        </div>
      <!--        概览区-->
      <div class="">
        <el-row :gutter="12" style="text-align: center">
          <el-col :span="4" >
            <el-card shadow="hover">
              总人数<br> {{huiyuanTotal}}
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="hover">
              总金额<br>{{yueTotal}}
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="hover">
              总积分<br> {{jifenTotal}}
            </el-card>
          </el-col>
        </el-row>
      </div>

        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="kaHao" label="卡号" />
            <el-table-column width="180" prop="xingMing" label="姓名" />
            <el-table-column width="180" prop="shouJiHao" label="手机号" />
            <el-table-column width="180" prop="huiYuanLeiXing" label="会员类型" >
                <x-dict-show slot-scope="{row}" :code="row.huiYuanLeiXing" dictType="T_HUI_YUAN_LX" />
            </el-table-column>
            <el-table-column width="180" prop="plusZheKou" label="plus折扣" />
            <el-table-column width="180" prop="huiYuanDJ" label="会员等级" />
            <el-table-column width="180" prop="leiJiJiFen" label="累计积分" />
            <el-table-column width="180" prop="shengYuJiFen" label="剩余积分" />
            <el-table-column width="180" prop="shengYuJinE" label="剩余金额" />
            <el-table-column width="180" prop="chongZhiJinE" label="充值金额" />
            <el-table-column width="180" prop="zengSongJinE" label="赠送金额" />
            <el-table-column width="180" prop="huiYuanBiaoQian" label="会员标签" />
            <el-table-column width="180" prop="kaZhuangTai" label="卡状态" >
                <x-dict-show slot-scope="{row}" :code="row.kaZhuangTai" dictType="T_KA_ZT" />
            </el-table-column>
            <el-table-column width="180" prop="kaiKaSJ" label="开卡时间" />
            <el-table-column width="180" prop="shiFuZhuXiao" label="是否注销" >
                <x-dict-show slot-scope="{row}" :code="row.shiFuZhuXiao" dictType="T_YES_OR_NO" />
            </el-table-column>
            <el-table-column width="180" prop="suoShuMenDian" label="所属门店" />
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
<!--                    <el-dropdown-item>-->
<!--                        <el-button type="success" size="mini" round @click="xiangQingShow(row.id)">详细信息<i class="el-icon-tickets el-icon&#45;&#45;right"></i></el-button>-->
<!--                    </el-dropdown-item>-->
                    <el-dropdown-item>
                        <el-button v-if="row.shiFuZhuXiao!==yesOrNo.YES.key"  type="danger" size="mini" round  @click="toChongZhi(row.id)" icon="el-icon-coin">充值</el-button>
                    </el-dropdown-item>



                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
        <xiang-qingxx ref="xiangQingxx"/>
        <to-chong-zhi ref="toChongZhi" @success="refresh"/>



    </div>
</template>
<script>
    import * as service from "@/service/hygl/HuiYuanXX";
    import * as menDianXXservice from "@/service/mdgl/MenDianXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/hyxx/HuiYuanXXEdit";
    import Detail from "@/view/hygl/hyxx/HuiYuanXXDetail";
    import XiangQingxx from "@/view/hygl/hyxx/XiangQingxx";
    import {ruleBuilder,isDigits, validMobilePhone} from "@/util/validate";
    import {DICT_TYPE_CHONG_ZHI_WAY, DICT_TYPE_KA_ZT, DICT_YES_OR_NO} from "@/util/constant";
    import ToChongZhi from "@/view/hygl/czgl/ToChongZhi";

    export default {
        name: "HuiYuanChongZhi",
        mixins: [XTableBase],
        components: {Edit, Detail,XiangQingxx, ToChongZhi},
        data() {
            this.refreshService = service.list;
            this.service = service;
            this.exportService = service.excelIExport;
            let {required,mobilePhone,positiveZeroTwo,digits,isDigits}=ruleBuilder;
            return {
                exportData:[],
                czWay:DICT_TYPE_CHONG_ZHI_WAY,
                kaZT:DICT_TYPE_KA_ZT,
                yesOrNo:DICT_YES_OR_NO,
                search: {
                    kaHao: '',
                    xingMing: '',
                    shouJiHao: '',
                    huiYuanLeiXing:'',
                    kaZhuangTai: '',
                    kaiKaSJBegin: '',
                    kaiKaSJEnd: '',
                    suoShuMenDian: '',
                },
                //概览数据
                huiyuanTotal:'',
                yueTotal:'',
                jifenTotal:'',
                //门店信息
                menDianMC:"",
                userName:"",
                drMenDian:'',
                isShanghu:false,
                //会员信息
                hyxx:{

                },
                hydjList:[
                    {
                        classification: "",
                        createTime: "",
                        creatorId: "",
                        huiYuanDJ: "",
                        huiYuanMC: "",
                        id: "",
                        ownerAreaCode: "",
                        ownerDeptCode: "",
                        ownerDeptName: "",
                        ownerOrganCode: "",
                        ownerOrganName: "",
                        updateTime: "",
                        updaterId: "",
                        xiangShouZheKou: null,
                        zuiDiJiFen: null,
                    }
                ],

                rules:{
                    shouJiHao:[required(),mobilePhone()],
                    jinE:[required()],
                    dengJi:[required()],
                    jiFen:[required()],
                    zk:[required(),positiveZeroTwo()],
                    fangShi:[required()],
                    shifu:[{ required: true, message: '充值金额不能为空', trigger:'blur'},digits()],
                    zengsong:[{ required: true, message: '赠送金额不能为空', trigger:'blur'},digits()],
                    pwd:[{ min: 6, max: 10, message: '长度在 6 到 10 个字符' ,trigger:'blur' },],
                },

                formLabelWidth: '120px',
                xxdrFormVisible:false,
                menDianList:[]
            }
        },
    mounted() {
        // console.log(service.total())
        service.total(0).then(res=>{
            console.log(res.data)
            this.huiyuanTotal=res.data.huiYuanTotal
            this.jifenTotal=res.data.jiFenTotal
            this.yueTotal=res.data.yuETotal
            // console.log(this.huiyuanTotal)
            // console.log(this.jifenTotal)
            // console.log(this.yueTotal)
        })
        service.hydjlist().then(res=>{
            if (res.code===200){
                console.log(res.data)
                this.hydjList=res.data;
            }
        })

        service.excelIExport().then(res=>{
            if (res.code===200){
                this.exportData=res.data;
            }
        })

        // 获取当前门店和操作人
        menDianXXservice.getCurrentMenDianXXFromRedis().then(res=>{
            console.log("这是store.user信息：",this.$store.getters.user);
            if(!res.data){
                menDianXXservice.checkboxList().then(res=>{
                    console.log("这是门店列表：",res);
                    if (res.code===200){
                        this.menDianList=res.data
                    }
                });
                this.userName=this.$store.getters.user.name;
                this.menDianMC="商户"
                this.isShanghu=true;
            }else {
                this.userName=this.$store.getters.user.name;
                this.menDianMC=res.data.menDianMC;
                this.drMenDian=res.data.menDianMC;
                console.log("这是导入门店：",this.drMenDian);
                console.log("这是登录用户：",this.userName);
                console.log("这是登录门店：",this.menDianMC)
            }
        })

        //获取所有门店
        menDianXXservice.checkboxList().then(res=>{
            console.log(res);
            if (res.code===200){
                this.menDianList=res.data
            }
        });


    },
      methods:{

          toChongZhi(id){
              this.$refs.toChongZhi.chongzhishow(id)
          },

      }

    }
</script>

<style scoped>
</style>
